@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h3 span {
  animation: bounce-animation infinite 3s ease;
  display: inline-block;
}

@keyframes bounce-animation {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-25px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  55% {
    transform: scale(1, 1) translateY(-2px);
  }
  60% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.divider::before {
  content: "";
  position: absolute;
  height: 90%;
  width: 2px;
  background: gray;
  top: 0;
  left: 0%;
}

.divider::after {
  content: "";
  position: absolute;
  height: 90%;
  width: 2px;
  background: gray;
  top: 0;
  left: 100%;
}

@media (max-width: 768px) {
  .divider::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 90%;
    background: gray;
    top: -5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .divider::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 90%;
    background: gray;
    top: 101%;
    left: 50%;
    transform: translateX(-50%);
  }
}
